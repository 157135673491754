;(function($, window, undefined) {

    $(function() {

        var user_type = $("#registerForm input[name=user_type]");
        user_type.on("change", display_serialnum);
        function display_serialnum() {

            var checked = $("#registerForm input[name=user_type]:checked");
            if (checked.val() === "0") {

                $("#id_serial_number").closest("li").show();

            } else if (checked.val() === "1") {

                $("#id_serial_number").val("");
                $("#id_serial_number").closest("li").hide();

            } else {

                $("#id_serial_number").closest("li").hide();

            }

        }
        display_serialnum();

    });

})(jQuery, window);
